/* -------------------------- */
/* Spacing Utility Classes    */
/* -------------------------- */

.u-mlAuto {
  margin-left: auto !important;
}


/* Margins */

.u-m0 {
  margin: 0rem !important;
}
.u-m05 {
  margin: 0.5rem !important;
}
/* .u-m1 {
  margin: 1rem !important;
} */
.u-m2 {
  margin: 2rem !important;
}
/* .u-m3 {
  margin: 3rem !important;
}
.u-m4 {
  margin: 4rem !important;
}
.u-m5 {
  margin: 0.5rem !important;
} */
.u-mt0 {
  margin-top: 0rem !important;
}
.u-mt05 {
  margin-top: 0.5rem !important;
}
.u-mt1 {
  margin-top: 1rem !important;
}
.u-mt2 {
  margin-top: 2rem !important;
}
.u-mt3 {
  margin-top: 3rem !important;
}
.u-mt4 {
  margin-top: 4rem !important;
}
.u-mt5 {
  margin-top: 0.5rem !important;
}
.u-mb0 {
  margin-bottom: 0rem !important;
}
.u-mb05 {
  margin-bottom: 0.5rem !important;
}
.u-mb1 {
  margin-bottom: 1rem !important;
}
.u-mb2 {
  margin-bottom: 2rem !important;
}
.u-mb3 {
  margin-bottom: 3rem !important;
}
.u-mb4 {
  margin-bottom: 4rem !important;
}
/* .u-mb5 {
  margin-bottom: 0.5rem !important;
} */
.u-ml0 {
  margin-left: 0rem !important;
}
.u-ml05 {
  margin-left: 0.5rem !important;
}
.u-ml1 {
  margin-left: 1rem !important;
}
.u-ml2 {
  margin-left: 2rem !important;
}
.u-ml3 {
  margin-left: 3rem !important;
}
/* .u-ml4 {
  margin-left: 4rem !important;
}
.u-ml5 {
  margin-left: 0.5rem !important;
} */
.u-mr0 {
  margin-right: 0rem !important;
}
.u-mr05 {
  margin-right: 0.5rem !important;
}
.u-mr1 {
  margin-right: 1rem !important;
}
.u-mr2 {
  margin-right: 2rem !important;
}
.u-mr3 {
  margin-right: 3rem !important;
}
/* .u-mr4 {
  margin-right: 4rem !important;
} */
/* .u-mr5 {
  margin-right: 0.5rem !important;
} */


/* Padding */

.u-p0 {
  padding: 0rem !important;
}
.u-p05 {
  padding: 0.5rem !important;
}
.u-p1 {
  padding: 1rem !important;
}
.u-p2 {
  padding: 2rem !important;
}
/* .u-p3 {
  padding: 3rem !important;
}
.u-p4 {
  padding: 4rem !important;
}
.u-p5 {
  padding: 0.5rem !important;
} */
.u-pt0 {
  padding-top: 0rem !important;
}
/* .u-pt05 {
  padding-top: 0.5rem !important;
}
.u-pt1 {
  padding-top: 1rem !important;
}
.u-pt2 {
  padding-top: 2rem !important;
} */
.u-pt3 {
  padding-top: 3rem !important;
}
/* .u-pt4 {
  padding-top: 4rem !important;
}
.u-pt5 {
  padding-top: 0.5rem !important;
} */
.u-pb0 {
  padding-bottom: 0rem !important;
}
/* .u-pb05 {
  padding-bottom: 0.5rem !important;
} */
.u-pb1 {
  padding-bottom: 1rem !important;
}
/* .u-pb2 {
  padding-bottom: 2rem !important;
}
.u-pb3 {
  padding-bottom: 3rem !important;
}
.u-pb4 {
  padding-bottom: 4rem !important;
} */
.u-pb5 {
  padding-bottom: 0.5rem !important;
}
/* .u-pl0 {
  padding-left: 0rem !important;
} */
.u-pl05 {
  padding-left: 0.5rem !important;
}
.u-pl1 {
  padding-left: 1rem !important;
}
.u-pl2 {
  padding-left: 2rem !important;
}
/* .u-pl3 {
  padding-left: 3rem !important;
}
.u-pl4 {
  padding-left: 4rem !important;
}
.u-pl5 {
  padding-left: 0.5rem !important;
} */
/* .u-pr0 {
  padding-right: 0rem !important;
}
.u-pr05 {
  padding-right: 0.5rem !important;
} */
.u-pr1 {
  padding-right: 1rem !important;
}
.u-pr2 {
  padding-right: 2rem !important;
}
/* .u-pr3 {
  padding-right: 3rem !important;
}
.u-pr4 {
  padding-right: 4rem !important;
}
.u-pr5 {
  padding-right: 0.5rem !important;
} */