.public-layout, .admin-layout {

.Search {
  align-items: center;
  background-color: white;
  border: 1px solid $mediumGrey;
  box-sizing: border-box;
  display: flex;
  font-size: 0.9375rem;
  padding: 0 0.5rem;
  position: relative;
  height: 3rem;

  img {
    margin-right: 0.5rem;
    min-width: 0.875rem;
  }

  > input, > .geosuggest {
    flex: 1;
    margin-top: 0.1rem;
  }

  input {
    border: 0;
    line-height: 1;
    outline: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__Clear {
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1rem;
    margin-right: 0.25rem;
    margin-top: 0.15rem;
  }

  .geosuggest__input {
    padding: 0;
  }

  .geosuggest__suggests {
    border-color: $mediumGrey !important;
    left: -31px;
    width: calc(100% + 59px);
  }

  @mixin above $medium {
    width: 160px;
  }

  @mixin above 1000px {
    width: 200px;
  }

  @mixin above 1220px {
    width: 240px;
  }
}

.Search--location {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  input {
    background-color: transparent;
  }

  @mixin above $medium {
    border-right-width: 0;
  }
}

.Search--term {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  @mixin above 1380px {
    min-width: 350px;
  }
}

.Search--location-wrapper {
  display: flex;

  @mixin below $small {
    flex-wrap: wrap;
    justify-content: center;

    .Search {
      width: 100% !important;
      margin-bottom: 0.5rem;
      margin-right: 0 !important;
    }

    .Button {
      width: 100% !important;
    }
  }

  .Search {
    border-radius: 4px;
    border-right-width: 1px;
    margin-right: 0.5rem;
    width: 60%;

    input {
    }

    @mixin above $small {
      flex: 2;
      width: unset;

      input {
        max-width: unset;
      }
    }
  }

  .Button {
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 40%;

    @mixin above $small {
      flex: 1;
      width: unset;
    }
  }
}

}