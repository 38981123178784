/* ------------------------- */
/* Base HTML Elements        */
/* No classes or IDs         */
/* ------------------------- */

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  @mixin inter;
  line-height: $base-line-height;
  transition: background 500ms;
  margin: 0;
}

strong, b {
  font-weight: 600;
}

p {
  margin: 0;
  margin-bottom: $global-padding;
}

p, h1, h2, h3, h4,
ul, section, pre, div, button, a {
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $darkGrey;
  text-decoration: none;
  transition: color $hover-speed;

  &:hover {
    color: $otherGrey;
  }
}

ol, ul {
  padding-left: 0;

  li {
    margin-left: 1.25rem;
  }
}

hr {
  border: none;
  height: 1px;
  width: 100%;
  margin-bottom: 1rem;
  background: rgba(0,0,0,0.1);
  margin-top: 0;
}

img {
  max-width: 100%;
}

form {
  width: 100%;
}

input {
  font-size: 100%;
}

label {
  display: block;
  user-select: none;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=number],
textarea {
  outline: none;
  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
}

input, textarea {
  @mixin inter;

  &::placeholder {
    /* @mixin inter; */
    color: $otherGrey;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}