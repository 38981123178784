@define-mixin inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
}

@define-mixin phosphate {
  font-family: "phosphatepro-solid", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}