/* -------------------------- */
/* Type Utility Classes    */
/* -------------------------- */


/* Sizing */

.u-big {
  @mixin above $small {
    font-size: 1.3125rem;
  }
}

.u-small {
  @mixin above $small {
    font-size: 0.875rem;
  }
}

.u-reallySmall {
  font-size: 0.8rem;

  @mixin above $small {
    font-size: 0.7rem;
  }
}


/* Case */

.u-upperCase {
  text-transform: uppercase;
}


/* Weight */

.u-strong {
  font-weight: bold;
}

.u-lightWeight {
  font-weight: 300;
}

/* Color */

.u-lightGrey {
  color: $otherGrey
}