/* ----------------------- */
/* Sizing Utility Classes  */
/* ----------------------- */

.u-limitWidth20 {
  width: 100%;
  max-width: 20rem !important;
}

.u-limitWidth30 {
  width: 100%;
  max-width: 30rem !important;
}

.u-limitWidth35 {
  width: 100%;
  max-width: 35rem !important;
}

.u-w100 {
  width: 100% !important;
}

.u-h100 {
  height: 100%;
}
