@import '../../../../stylesheets/shared/content/fonts.postcss';
@import '../../../../stylesheets/utils/mixins.postcss';
@import '../../../../stylesheets/variables.postcss';
@import '../../../../stylesheets/admin/variables.postcss';

.GlobalHeader {
  position: relative;
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1500;
  margin-bottom: 0;

  :global(.Container) {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  @mixin above $medium {
    height: $nav-height;
    margin-bottom: 0rem;
  }
}

.GlobalHeader_logo {
  flex: 1 1 auto;
  display: block;
  padding: 1rem;

  img {
    display: block;
    width: 180px;
    max-width: 180px;
  }

  @mixin above $medium {
    height: $nav-height;

    img {
      display: block;
    }
  }

  @mixin below $medium {
    padding: 0.75rem 0.5rem;
    padding-left: 0;
  }
}

.GlobalHeader_search {
  display: none !important;

  @mixin above $medium {
    display: flex !important;
  }
}

.headerMenuToggle {
  border: none;
  background: none;
  padding: .75rem 1rem;
  padding-top: .5rem;
  width: 52px;

  &:focus {
    outline: none;
  }
}

.headerMenuIcon {
  margin: -22px -14px;
  margin-right: 0;

  @mixin above $medium {
    display: none;
  }
}

.GlobalHeader_search2,
.GlobalHeader_nav {
  position: fixed;
  width: 100%;
  height: calc(100% - $nav-height);
  top: $nav-height;
  left: 0;
  background: white;
  z-index: 1200;
  overflow-y: auto;
}

.GlobalHeader_nav {
  @mixin above $medium {
    position: static;
    text-align: right;
  }

  @mixin below $medium {
    padding: 1rem;
    padding-top: 0;
  }
}

.GlobalHeader_subNav {

  &.active {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $lightGrey;
    z-index: 1600;
    overflow-y: auto;

    @mixin above $medium {
      display: none;
    }
  }

  .SubNav_close {
    border: none;
    padding: 7px;
    padding-bottom: 0;
    background: none;
  }

  .SubNav_content {
    margin-top: 2px;
  }

  .GlobalHeader_dropdownLinks {
    padding: 0 1rem;
  }
}

.GlobalHeader_search2 {
  padding-top: 1.5rem;
  text-align: center;

  @mixin above $medium {
    display: none;
  }

  .CombinedSearch {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    padding-top: 0;

    &_resetButton {
      margin-top: auto;
      margin-left: 0 !important;
      background: none;
      border: 1px solid $mediumGrey;
      color: #8F949F;
    }
  }

  :global(.Search) {
    border-radius: 4px;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  :global(.Button) {
    justify-content: center;
  }
}

.GlobalHeader_navLink {
  font-weight: 500;
  color: black !important;
  display: block;
  font-size: 1.25rem;
  line-height: 1;
  padding: .75rem 0;
  padding-top: 1rem;
  width: 100%;
  background: none;
  text-align: left;
  border: none;
  border-bottom: 1px solid $mediumGrey;

  @mixin above $medium {
    display: inline-block;
    width: unset;
    font-size: 15px;
    margin-bottom: 0;
    margin-right: 1rem;
    line-height: 1;
    padding: 0;
    padding-top: 12px;
    border: none;
  }

  @mixin above $large {
    margin-right: 1.5rem;
  }
}

.GlobalHeader_navLink a, a.GlobalHeader_navLink {
  color: black !important;

  &:hover {
    color: $otherGrey !important;
  }
}

.GlobalHeader_dropdownLinks {
  display: flex;
  flex-direction: column;

  @mixin above $medium {
    .GlobalHeader_navLink {
      padding: 6px;
      margin: 0;

      &:first-child {
        padding-top: 9px;
      }

      &:last-child {
        padding-bottom: 5px;
      }
    }
  }
}

.GlobalHeader_navButton {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  &:first-of-type {
    margin-top: 2.5rem;
  }

  @mixin above $medium {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 1rem;

    &:first-of-type {
      margin-top: 0;
    }

    .Button {
      width: unset;
    }
  }
}

.GlobalHeader_navControls {
  display: none !important;

  @mixin above $medium {
    display: inline-flex !important;
    align-items: center;
    margin-bottom: 0;
    padding-right: 1rem;
  }
}

.GlobalHeader_nav :global(.Button) {
  display: block;

  @mixin above $medium {
    display: inline-block;
    margin-bottom: 0;
  }
}

.GlobalHeader:not(.GlobalHeader__menuOpen) {
  .GlobalHeader_nav {
    @mixin below $medium {
      display: none;
    }
  }
}

.GlobalHeader:not(.GlobalHeader__searchOpen) {
  .GlobalHeader_search2 {
    @mixin below $medium {
      display: none;
    }
  }
}

/* Header Notifications */

.HeaderNotifications {
  display: inline-flex;
  position: relative;
}

.HeaderNotifications__open {
  .HeaderNotifications_bell {
    color: $black;
  }
}

.HeaderNotifications_bell {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 24px;
  }

  &:hover svg {
    fill: $otherGrey;
  }

  &:active, &:focus {
    outline: none;
  }
}

.HeaderNotifications_count {
  font-weight: 600;
  display: block;
  position: absolute;
  top: 1px;
  right: 3px;
  line-height: 1.5;
  background: $red;
  color: white;
  font-size: 9px;
  border-radius: 50%;
  padding: 1px;
  padding-bottom: .25px;
  min-width: 1.6em;
  min-height: 1.5em;
  text-align: center;
  transform: translateX(35%);
}

.HeaderNotifications_popUp {
  width: 300px;
}

.HeaderNotifications_popUpContent {
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;
}

.HeaderNotification {
  display: block;
  background: #eee;
  border-radius: $radius;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  color: $black;
  transition: background $hover-speed;

  &:hover {
    background: $lightYellow;
  }
}

.HeaderNotification_date {
  @mixin h5;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}

.HeaderNotification_content {
  font-size: 0.875em;
  line-height: 1.3;
}

.HeaderNotifications_header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 1rem;

  h5 {
    line-height: 1.75;
    flex: 1 1 100%;
  }

  :global(.Button) {
    white-space: nowrap;
  }
}

.GlobalHeader .AccountIcon {
  cursor: pointer;
  height: 30px;
  width: 30px;

  &:hover svg {
    fill: $otherGrey;
  }

  img {
    height: 32px;
  }
}

.ServicesDropdown_inner {
  a {
    color: black;

    &:hover {
      color: $otherGrey;
    }
  }
}

.NearMeTrigger {
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: $otherGrey !important;
  }
}

.NearMePopover {
  background: $lightGrey;
  padding: 2rem 0;
  text-align: left;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.NearMeContent:global(.u-grid-row) {
  margin-top: 12px;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-right: 0 !important;
  margin-left: 0 !important;

  strong {
    font-weight: 500;
  }

  li {
    margin: 0;
    list-style: none;
  }

  li,
  strong {
    white-space: unset;
  }

  :global(.u-grid-col) {
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  @mixin above $medium {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.75rem;

    li,
    strong {
      white-space: nowrap;
    }

    :global(.u-grid-col) {
      padding: 0 2rem;
      margin-bottom: 0.5rem;
    }
  }
}

/* Admin only (catering dashboard) */
:global(.admin-layout) {

  .GlobalHeader {
    background: $bg;
    position: static;

    :global(.Button) {
      font-weight: 500;
      font-weight: bold;
      background: none;
      color: black;
      border: 1px solid $otherGrey;
      border-radius: 4px;

      &:hover {
        background-color: $grey;
        color: black;
      }
    }

    :global(.Button--small) {
      font-size: 14px !important;
    }

    a.GlobalHeader_navLink {
      color: black;
    }

    :global(.geosuggest__input) {
      border: none;

      &:focus {
        border: none;
      }
    }
  }

  .GlobalHeader_search2,
  .GlobalHeader_nav {
    background: $bg;
  }

  .NearMePopover {
    background: $bg;
    margin-top: 3px;
  }

  .HeaderNotification {
    background: $primary-bg;

    &:hover {
      background: $primary-bg-dark;
    }
  }

  .HeaderNotifications_count {
    top: -4px;
    right: 0px;
    background: $primary;
    border: 1px solid $primary-bg;
    font-size: .6rem;
    min-width: 1.9em;
  }

  .HeaderNotification_date {
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 12px;
    line-height: 1;
    color: black;
  }

}