/* TODO replace this entirely with CSS grid, then remove lost */

/* ------------------------ */
/* Grid Utility Classes     */
/* ------------------------ */

@lost gutter 0rem;
@lost flexbox flex;
$gutter: 1rem;

.u-grid-row {
  lost-center: 100% !important;
  align-items: flex-start !important;
  justify-content: left !important;
  margin-bottom: 1rem;
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
  max-width: none !important;
  flex-direction: column;
  flex-wrap: wrap;

  @mixin above $medium {
    margin-bottom: 0;
  }
}

.u-grid-row--center {
  align-items: center !important;
  justify-content: center !important;
}

.u-grid-col {
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @mixin above $medium {
    margin-bottom: 0;
  }
}

.u-grid-half {
  lost-column: 1/2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.u-grid-sm-half {
  @mixin above $small {
    lost-column: 1/2;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.u-grid-md-half {
  margin-bottom: 1rem;

  @mixin above $medium {
    margin-bottom: 0;
    lost-column: 1/2;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* .u-grid-lg-half {
  @mixin above $large {
    lost-column: 1/2;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
} */

/* .u-grid-third {
  lost-column: 1/3;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
} */

.u-grid-sm-third {
  @mixin above $small {
    lost-column: 1/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* .u-grid-sm-twoThirds {
  @mixin above $small {
    lost-column: 2/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
} */


.u-grid-md-third {
  @mixin above $medium {
    lost-column: 1/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}


/* .u-grid-md-twoThirds {
  @mixin above $medium {
    lost-column: 2/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
} */

.u-grid-lg-third {
  @mixin above $large {
    lost-column: 1/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.u-grid-lg-twoThirds {
  @mixin above $large {
    lost-column: 2/3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* .u-grid-quarter {
  lost-column: 1/4;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.u-grid-sm-quarter {
  @mixin above $small {
    lost-column: 1/4;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
} */

.u-grid-md-quarter {
  @mixin above $medium {
    lost-column: 1/4;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* .u-grid-lg-quarter {
  @mixin above $large {
    lost-column: 1/4;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
} */
