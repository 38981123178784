/* Display */

.u-block {
  display: block !important;
}

.u-flex {
  display: flex !important;
}

.u-align-center {
  align-items: center !important;
}

.u-align-flex-end {
  align-items: flex-end !important;
}

.u-flex-column {
  flex-direction: column !important;
}

/* Floats */

.u-pullRight {
  float: right !important;
}

.u-pullLeft {
  float: left !important;
}


/* Alignment */

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

.u-blockCenter {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}